import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root',
})
export class ApiService {
	constructor(private http: HttpClient) {}

	get$<T>(url: string, options = {}) {
		return this.http.get<T>(url, options);
	}

	post$<T>(url: string, body: unknown, options = {}) {
		return this.http.post<T>(url, body, options);
	}

	put$<T>(url: string, body: unknown) {
		return this.http.put<T>(url, body);
	}

	patch$<T>(url: string, body: unknown) {
		return this.http.patch<T>(url, body);
	}

	delete$<T>(url: string) {
		return this.http.delete<T>(url);
	}
}
